'use client';

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';

import Title from '../HighlightedNews/Title';
import {
  categoryBuilder,
  slug,
  tagsBuilder,
  titleBuilder,
} from '@/utils/functions';
import FormNews from '../FormNews';
import SocialNetworksNews from '../SocialNetworksNews';

export default function FiltersNews({
  allNews,
  onFilter,
  socialNetworksContent,
  searchOnPress,
}) {
  const { push } = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const path = pathname.split('/')[1];

  let initialCategory;
  let initialTags;

  if (typeof window !== 'undefined') {
    initialCategory =
      searchParams.get('categoria') ||
      localStorage?.getItem('categoria') ||
      null;
    initialTags =
      searchParams.getAll('tags').length > 0
        ? searchParams.getAll('tags')[0].split(',') ||
          localStorage?.getItem('tags').split(',')
        : [];
  }

  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [selectedTags, setSelectedTags] = useState(initialTags);
  const [searchTerm, setSearchTerm] = useState('');

  const categories = categoryBuilder(allNews);
  const tags = tagsBuilder(allNews);

  const handleCategoryChange = (category) => {
    const newCategory = selectedCategory === category ? null : category;
    setSelectedCategory(newCategory);

    const params = new URLSearchParams(searchParams);
    if (newCategory) {
      params.set('categoria', newCategory);
      localStorage.setItem('categoria', newCategory);
    } else {
      params.delete('categoria');
      localStorage.removeItem('categoria');
    }
    if (path.includes('/conteudos')) {
      push(`?${params.toString()}#container-filter`);
    } else {
      push(`/conteudos?${params.toString()}`);
    }

    applyFilters(newCategory, selectedTags, searchTerm);
  };

  const handleTagChange = (tag) => {
    let updatedTags = [];
    if (selectedTags.includes(tag)) {
      updatedTags = selectedTags.filter((t) => t !== tag);
    } else {
      updatedTags = [...selectedTags, tag];
    }
    setSelectedTags(updatedTags);

    const params = new URLSearchParams(searchParams);
    if (updatedTags.length > 0) {
      params.set('tags', updatedTags.join(','));
      localStorage.setItem('tags', updatedTags.join(','));
    } else {
      params.delete('tags');
      localStorage.removeItem('tags');
    }
    if (path.includes('/conteudos')) {
      push(`?${params.toString()}#container-filter`);
    } else {
      push(`/conteudos?${params.toString()}`);
    }

    applyFilters(selectedCategory, updatedTags, searchTerm);
  };

  const applyFilters = (category, tags, searchTerm) => {
    const filtered = allNews.filter((newsItem) => {
      const categoryMatch = category ? newsItem.category === category : true;
      const tagsMatch = tags.length
        ? tags.every((tag) => newsItem.tags.includes(tag))
        : true;
      const searchMatch = searchTerm
        ? newsItem.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
          newsItem.title.toLowerCase().includes(searchTerm.toLowerCase())
        : true;

      return categoryMatch && tagsMatch && searchMatch;
    });

    onFilter(filtered);
  };

  const getTitleAutoCompleteReq = () => {
    const titles = titleBuilder(allNews);
    const options = titles;
    return options.map((t) => ({
      value: t.title,
      label: t.title,
      link: `/conteudo/${slug(t.title)}`,
    }));
  };

  const navigateToPost = (e) => {
    push(e.link);
  };

  const handleSearchChange = (inputValue) => {
    if (searchOnPress) {
      const newsFiltered = allNews.filter((newsItem) => {
        return newsItem.title.toLowerCase().includes(inputValue?.toLowerCase());
      });

      onFilter(newsFiltered);
    }
  };

  useEffect(() => {
    applyFilters(selectedCategory, selectedTags, searchTerm);
  }, [selectedCategory, selectedTags, searchTerm]);

  const colourStyles = {
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#96bf00' : isSelected ? '#96bf00' : null,
      };
    },
  };

  const clearFilters = () => {
    setSelectedCategory(null);
    setSelectedTags([]);
    setSearchTerm('');

    localStorage.removeItem('categoria');
    localStorage.removeItem('tags');

    const params = new URLSearchParams(searchParams);
    params.delete('categoria');
    params.delete('tags');
    push(`/conteudos?${params.toString()}`);

    onFilter(allNews);
  };

  return (
    <div>
      <div className="w-full flex flex-col gap-14">
        {/* Title Filter */}
        <div className="flex flex-col gap-4">
          <Title title="O que você procura hoje?" font="text-lg" />
          <div className="relative block">
            <Select
              id="titleSearch"
              instanceId="titleSearch"
              isClearable
              isSearchable
              options={getTitleAutoCompleteReq() || []}
              onChange={navigateToPost}
              placeholder="Pesquisar"
              onInputChange={handleSearchChange}
              className="max-w-[448px] fhd:max-w-full"
              styles={colourStyles}
            />
          </div>
        </div>

        {/* Categories Filter */}
        <div className="flex flex-col gap-4">
          <Title title="Categorias" font="text-lg" />
          <div className="flex flex-col gap-4">
            {categories &&
              categories.map((c, index) => (
                <div key={index} className="w-full h-full flex flex-col gap-0">
                  <button
                    type="button"
                    className={`text-customColorWhite w-full h-full flex flex-row items-center justify-between px-2 py-2 ${
                      selectedCategory === c.categoryName
                        ? 'bg-customColorGrayBorder/70 rounded-t-lg'
                        : ''
                    }`}
                    onClick={() => handleCategoryChange(c.categoryName)}
                  >
                    <span className="font-semibold tracking-wider">
                      {c.categoryName}
                    </span>
                    <span className="rounded-full bg-gradient-to-b from-customColorGrayBorder to-customColorBlackBg text-customColorWhite size-6 flex items-center justify-center">
                      {c.categoryCount}
                    </span>
                  </button>
                  <div className="w-full h-[1px] bg-customColorGreen200" />
                </div>
              ))}
          </div>
        </div>

        {/* Tags Filter */}
        <div className="flex flex-col gap-4">
          <Title title="Tags" font="text-lg" />
          <div className="flex flex-row gap-4 flex-wrap">
            {tags &&
              tags.map((tag, index) => (
                <div
                  key={index}
                  className={`hover:text-customColorGreen200 transition duration-300 hover:cursor-pointer ${
                    selectedTags?.includes(tag)
                      ? 'text-customColorGreen200'
                      : 'text-customColorWhite'
                  }`}
                  onClick={() => handleTagChange(tag)}
                >
                  {`#${tag}`}
                </div>
              ))}
          </div>
        </div>

        {/* Clear Filters Button */}
        {(selectedCategory || selectedTags?.length > 0) && (
          <div className="flex justify-start">
            <button
              onClick={clearFilters}
              className="text-customColorGreen200 border border-customColorGreen200 px-3 py-1 rounded-lg hover:bg-customColorGreen200 hover:text-customColorBlackBg font-semibold text-sm transition duration-300"
            >
              Limpar Filtros
            </button>
          </div>
        )}

        {/* Form Component */}
        <div className="hidden lg:flex flex-col gap-14">
          <FormNews />
          {socialNetworksContent && (
            <SocialNetworksNews socialNetworksContent={socialNetworksContent} />
          )}
        </div>
      </div>
    </div>
  );
}
