import getItemsCollection from '@/services/directus-cms/getItemsCollection';
import slugify from 'slugify';

export function getUltimaImgem(imgArray) {
  if (!imgArray || !imgArray.length) return null;

  return imgArray[imgArray.length - 1];
}

export function ordenaLista(array, chave, ordem) {
  const listaOrdenada = array.sort((a, b) => {
    let aVal = a[chave];
    let bVal = b[chave];

    if (!chave) {
      aVal = a;
      bVal = b;
    }

    if (aVal > bVal) return 1;

    if (aVal < bVal) return -1;

    return 0;
  });

  return ordem === 'desc' ? listaOrdenada.reverse() : listaOrdenada;
}

export function slug(frase) {
  if (!frase) {
    return '';
  }
  // Substitui caracteres especiais por hífen, ignorando ç e letras com acento
  const fraseLimpa = frase.replace(/[^a-z\d\sçÀ-ÿ]+/gi, '-');
  return slugify(fraseLimpa, { lower: true });
}

export function removeHTMLTags(str) {
  const replace = str.replace(/<\/?[^>]+(>|$)/g, '');
  return replace;
}

export function categoryBuilder(allNews) {
  const categoryCounts = allNews.reduce((acc, newsItem) => {
    const category = newsItem.category;

    const categoryIndex = acc.findIndex(
      (item) => item.categoryName === category
    );

    if (categoryIndex !== -1) {
      acc[categoryIndex].categoryCount++;
    } else {
      acc.push({ categoryName: category, categoryCount: 1 });
    }

    return acc;
  }, []);

  return categoryCounts;
}

export function titleBuilder(allNews) {
  const title = allNews.filter((newsItem) => newsItem.title);
  const uniqueTitles = [...new Set(title)];
  return uniqueTitles;
}

export function tagsBuilder(allNews) {
  const tags = allNews
    .filter((newsItem) => newsItem.tags)
    .flatMap((newsItem) => newsItem.tags);

  const uniqueTags = [...new Set(tags)];
  return uniqueTags;
}

export function sortNews(newsCollection) {
  const sortedNews = newsCollection.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  return sortedNews;
}

export async function generateSitemapNews() {
  const newsCollection = await getItemsCollection({
    fields: ['*', 'files.directus_files_id.*'],
    collectionName:
      process.env.DIRECTUS_BLOG_COLLECTION ||
      process.env.NEXT_PUBLIC_DIRECTUS_BLOG_COLLECTION,
  });
  const allNews = sortNews(newsCollection);
  return allNews;
}
