import React from 'react';

export default function Title({ title, font }) {
  return (
    <div className="w-fit flex flex-col gap-1">
      <h2
        className={`${
          font ? font : 'text-xl 2xl:text-2xl'
        } tracking-[.25em] font-bold uppercase text-customColorWhite`}
      >
        {title}
      </h2>
      <div className="w-[20%] h-1 bg-customColorGreen300" />
    </div>
  );
}
