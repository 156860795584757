import React from 'react';
import Title from '../HighlightedNews/Title';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';

export default function SocialNetworksNews({ socialNetworksContent }) {
  const data = socialNetworksContent?.json_editor?.items;
  return (
    <div className="flex flex-col gap-6">
      <Title title="Redes Sociais" font="text-lg" />
      <div className="w-full flex flex-row items-center gap-4">
        {data &&
          data.map((c, index) => (
            <Link href={c.link} key={index} aria-label={`Link para ${c.name}`}>
              <FontAwesomeIcon
                icon={
                  c.name === 'Instagram'
                    ? faInstagram
                    : c.name === 'Youtube'
                    ? faYoutube
                    : c.name === 'Linkedin'
                    ? faLinkedinIn
                    : c.name === 'Facebook'
                    ? faFacebookF
                    : c.name === 'X'
                    ? faXTwitter
                    : faAt
                }
                className="text-customColorGreen200 hover:scale-[1.05] transition duration-300 text-2xl"
              />
            </Link>
          ))}
      </div>
    </div>
  );
}
