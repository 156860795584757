import { readItems } from '@directus/sdk';
import directus from './directus';

async function getItemsCollection({ fields, collectionName, key }) {
  const filter = {};

  if (key) {
    filter.key = {
      _eq: key,
    };
  }

  if (Array.isArray(key)) {
    filter.key = {
      _in: key,
    };
  }

  const items = await directus.request(
    readItems(
      collectionName ||
        process.env.NEXT_PUBLIC_DIRECTUS_SITE_COLLECTION ||
        process.env.DIRECTUS_SITE_COLLECTION,
      {
        filter,
        fields: [...fields, 'author.*', 'file.*'] || '*',
      }
    )
  );

  function generateFileStructure(item) {
    if (item) {
      return item.map((file, index) => {
        const directusFile = file?.directus_files_id || {};

        return {
          fileUrl: directusFile.id
            ? `${process.env.NEXT_PUBLIC_DIRECTUS_URL}/assets/${directusFile.id}`
            : '',
          fileName: directusFile.title || '',
          fileDescription: directusFile.description || '',
          fileType: directusFile.type || '',
          fileTags: directusFile.tags || '',
          filePosition: index,
        };
      });
    } else {
      return [];
    }
  }

  function generateAuthorStructure(authorImage, authorName) {
    if (authorImage) {
      return {
        fileUrl: `${process.env.NEXT_PUBLIC_DIRECTUS_URL}/assets/${authorImage.id}`,
        fileName: authorName || '',
      };
    }
  }

  function generateOgImageStructure(ogImage) {
    if (ogImage) {
      return {
        fileUrl: `${process.env.NEXT_PUBLIC_DIRECTUS_URL}/assets/${ogImage.id}`,
      };
    }
  }

  const extractedFiles = items.map((item) => ({
    ...item,
    files: generateFileStructure(item.files),
    author: generateAuthorStructure(item.author, item.author_name),
    file: generateOgImageStructure(item.file),
  }));

  return extractedFiles;
}

export default getItemsCollection;
